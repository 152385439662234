// @flow
import JQueryView, { on } from '../../common/JQueryView';

export default class FormView extends JQueryView {
  others: boolean;

  constructor(others: boolean) {
    super('.simple_form');
    this.others = others;

    // 初期表示 クラスふよ
    $('input:visible ,select:visible ,textarea:visible').each(function() {
      const $el = $(this);
      // エラーではない、かつ入力ありならば is-activatedつける
      if (!$el.hasClass('is-invalid') && $el.val() !== '') {
        $el.addClass('is-activated');
      }
    });
    this.render();
  }

  @on('change #white_paper_user_role')
  onChangeOther() {
    var roleType = $('#white_paper_user_role').val();
    if (roleType == 'other') {
      this.others = true;
      this.render();
    } else {
      this.others = false;
      this.render();
    }
  }

  @on('change input,select,textarea')
  onChangeInputStyle(e: any) {
    const $el = $(e.currentTarget);
    if ($el.val() === '') {
      $el.removeClass('is-activated');
    } else {
      $el.addClass('is-activated').removeClass('is-invalid');

      $el
        .parents('.download-page-block')
        .find('.invalid-feedback')
        .hide();
    }
  }

  @on('change .js-download-input')
  onChangeHideError(e: any) {
    $(e.currentTarget)
      .parents('.download-check')
      .find('.invalid-feedback')
      .hide();
  }

  render() {
    if (this.others) {
      $('#white_paper_user_other_role').show();
    } else {
      $('#white_paper_user_other_role').hide();
    }
  }
}
