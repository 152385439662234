// @flow
import JQueryView, { on } from '../../../common/JQueryView';
import { CATEGORY } from '../../../../models/companies/IrCategory';
import { STATUS } from '../../../../models/companies/IrStatus';

declare var $: any;

export default class FormView extends JQueryView {
  canUseStockSelection: boolean;
  canUseOnlineMeeting: boolean;
  canUseOnlineVoting: boolean;
  hasCommunityUser: boolean;
  specifyDeliveryTime: boolean;
  status: number;

  constructor(
    canUseStockSelection: boolean,
    canUseOnlineMeeting: boolean,
    canUseOnlineVoting: boolean,
    hasCommunityUser: boolean,
    specifyDeliveryTime: boolean,
    status: number,
  ) {
    super('.simple_form');
    this.canUseStockSelection = canUseStockSelection;
    this.canUseOnlineMeeting = canUseOnlineMeeting;
    this.canUseOnlineVoting = canUseOnlineVoting;
    this.hasCommunityUser = hasCommunityUser;
    this.specifyDeliveryTime = specifyDeliveryTime;
    this.status = status;
    this.renderOnlineMeetingArea();
    this.renderOnlineVotingArea();
    this.renderRegistButtonArea();
    this.renderSendCommuntiyUserArea();
    this.renderResultReportArea();
    this.renderStartedAtArea();
  }

  @on('change input[name="company_ir[company_ir_category_id]"]:radio')
  onChangeIrCaregory() {
    this.renderOnlineMeetingArea();
    this.renderOnlineVotingArea();
    this.renderRegistButtonArea();
    this.renderSendCommuntiyUserArea();
    this.renderResultReportArea();
    this.renderStartedAtArea();
  }

  @on('change input[name="company_ir[specify_delivery_time]"]:radio')
  onChangeSpecifyDeliveryTime() {
    this.renderStartedAtArea();
  }

  renderOnlineMeetingArea() {
    if (this.isCategoryGeneralMeeting()) {
      $('#online_meeting_area').show();
    } else {
      $('#online_meeting_area').hide();
    }
  }

  renderOnlineVotingArea() {
    if (this.isCategoryGeneralMeeting()) {
      $('#online_voting_area').show();
    } else {
      $('#online_voting_area').hide();
    }
  }

  renderRegistButtonArea() {
    if (this.isCategoryGeneralMeeting()) {
      $('#confirm_button_for_general_meeting').show();
      $('#regist_button_for_general_meeting').hide();
    } else {
      $('#confirm_button_for_general_meeting').hide();
      $('#regist_button_for_general_meeting').show();
    }
  }

  renderSendCommuntiyUserArea() {
    if (this.isCategoryGeneralMeeting()) {
      $('.community-user').hide();
      $('#company_ir_send_community_stockholders').prop('checked', false);
      $('#company_ir_send_community_non_stockholders').prop('checked', false);
    } else {
      $('.community-user').show();
    }
  }

  renderResultReportArea() {
    if (this.isCategoryResultReport()) {
      $('#result_report_area').show();
    } else {
      $('#result_report_area').hide();
    }
  }

  renderStartedAtArea() {
    if (this.isCategoryGeneralMeeting()) {
      $('.specify_delivery_time').prop('checked', 'true');
      $('label[for="company_ir_specify_delivery_time_false"]').css({
        color: 'gray',
      });
    } else {
      $('label[for="company_ir_specify_delivery_time_false"]').css({
        color: '',
      });
    }

    if (this.isSpecifiedDeliveryTime()) {
      $('#started_at_area').show();
      $('#confirm_start_at_ir').show();
      this.renderConfirmStartAtIr();
      $('#confirm_nine_am').hide();
    } else {
      $('#started_at_area').hide();
      $('#confirm_start_at_ir').hide();
      $('#confirm_nine_am').show();
    }
  }

  isCategoryGeneralMeeting(): boolean {
    const irCategoryType = parseInt(
      $('input[name="company_ir[company_ir_category_id]"]')
        .filter(':checked')
        .val(),
    );
    if (
      irCategoryType === CATEGORY.extraMeeting ||
      irCategoryType === CATEGORY.ordinaryMeeting
    ) {
      return true;
    } else {
      return false;
    }
  }

  isCategoryResultReport(): boolean {
    const irCategoryType = parseInt(
      $('input[name="company_ir[company_ir_category_id]"]')
        .filter(':checked')
        .val(),
    );
    return irCategoryType === CATEGORY.resultReport;
  }

  isSpecifiedDeliveryTime(): boolean {
    if (
      this.status === STATUS.S_PUBLISHED ||
      this.status === STATUS.S_WAITING
    ) {
      // prettier-ignore
      return $('#company_ir_specify_delivery_time_true').prop('checked') || this.specifyDeliveryTime;
    } else {
      return $('#company_ir_specify_delivery_time_true').prop('checked');
    }
  }
  // このignoreは後で直します

  @on('click #select-stocks-all')
  onClickSelectStocksAll() {
    this.renderStockSelection(true);
  }

  @on('click #clear-stocks-all')
  onClickClearStocksAll() {
    this.renderStockSelection(false);
  }

  renderStockSelection(selected: boolean) {
    $('.stock-selection :checkbox').each((index, element) => {
      const $this = $(element);
      $this.prop('checked', selected);
    });
  }

  @on('click #confirm-send')
  onClickConfirmSend() {
    $('#confirm-modal').modal('show');
    this.renderConfirmStockSelection();
    this.renderConfirmOnlineMeeting();
    this.renderConfirmOnlineVoting();
    this.renderConfirmStartAtIr();
  }

  renderConfirmStockSelection() {
    if (this.canUseStockSelection === false) {
      $('#confirm_stock_selection').hide();
      return true;
    }

    $('#confirm_stock_selection').show();
    $('.confirm-selection-investor').html('');
    $('.confirm-selection-external').html('');

    let stockName;
    $('.stock-selection-investor :checkbox').each((index, element) => {
      const $this = $(element);
      stockName = $this
        .parent('.form-check')
        .children('label')
        .text();
      if ($this.prop('checked')) {
        $('.confirm-selection-investor').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6 font-weight-bold">配信する</div>',
        );
      } else {
        $('.confirm-selection-investor').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6">配信しない</div>',
        );
      }
    });

    $('.stock-selection-external :checkbox').each((index, element) => {
      const $this = $(element);
      stockName = $this
        .parent('.form-check')
        .children('label')
        .text();
      if ($this.prop('checked')) {
        $('.confirm-selection-external').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6 font-weight-bold">配信する</div>',
        );
      } else {
        $('.confirm-selection-external').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6">配信しない</div>',
        );
      }
    });

    $('.community-user :checkbox').each((index, element) => {
      const $this = $(element);
      stockName = $this
        .parent('.form-check')
        .children('label')
        .text();
      if ($this.prop('checked')) {
        $('.confirm-selection-community-user').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6 font-weight-bold">配信する</div>',
        );
      } else {
        $('.confirm-selection-community-user').append(
          `<div class="col-6">${stockName}</div>` +
            '<div class="col-6">配信しない</div>',
        );
      }
    });

    if (this.isCategoryGeneralMeeting() || !this.hasCommunityUser) {
      $('#community_user').hide();
    }
  }

  renderConfirmOnlineMeeting() {
    if (
      this.canUseOnlineMeeting === false ||
      this.isCategoryGeneralMeeting() === false
    ) {
      $('#confirm_online_Meeting').hide();
      return true;
    }

    const useOnlineMeeting = $('input[name="company_ir[use_online_meeting]"]')
      .filter(':checked')
      .val();

    $('#confirm_online_meeting').html('');

    let html = '';
    if (useOnlineMeeting === 'false') {
      html += '<div class="card"><div class="card-body">';
      html += '<div class="row">';
      html +=
        '<div class="col-6 font-weight-bold">株主総会のオンライン参加</div>';
      html += '<div class="col-6">実施しない</div>';
      html += '</div>';
      html += '</div></div>';
    } else {
      html += '<div class="card"><div class="card-body">';
      html += '<div class="row">';
      html +=
        '<div class="col-6 font-weight-bold">株主総会のオンライン参加</div>';
      html += '<div class="col-6">実施する</div>';
      html += '<div class="col-6 font-weight-bold">申し込み締切日時</div>';
      html += `<div class="col-6">${finishAtAccepting()}</div>`;
      html += '</div>';
      html += '</div></div>';
    }

    $('#confirm_online_meeting').html(html);
    $('#confirm_online_meeting').show();

    function finishAtAccepting() {
      const atYear = $(
        'select[name="company_ir[company_online_meeting_attributes][finish_at_accepting(1i)]"]',
      ).val();
      const atMonth = $(
        'select[name="company_ir[company_online_meeting_attributes][finish_at_accepting(2i)]"]',
      ).val();
      const atDay = $(
        'select[name="company_ir[company_online_meeting_attributes][finish_at_accepting(3i)]"]',
      ).val();
      const atHour = $(
        'select[name="company_ir[company_online_meeting_attributes][finish_at_accepting(4i)]"]',
      ).val();
      const atMinute = $(
        'select[name="company_ir[company_online_meeting_attributes][finish_at_accepting(5i)]"]',
      ).val();
      return `${atYear}年${atMonth}月${atDay}日 ${atHour}時${atMinute}分`;
    }
  }

  renderConfirmOnlineVoting() {
    if (this.canUseOnlineVoting === false) {
      $('#confirm_online_voting').hide();
      return true;
    }

    if (this.isCategoryGeneralMeeting() === false) {
      $('#confirm_online_voting').hide();
      return true;
    }

    $('#confirm_online_voting').show();
    $('.confirm-use-online-voting').html('');
    $('.confirm-general-meeting').html('');
    $('.confirm-general-meeting-agenda').html('');

    const useOnlineVoting = $('input[name="company_ir[use_online_voting]"]')
      .filter(':checked')
      .val();
    const generalMeetingTitle = $(
      'input[name="company_ir[company_general_meeting_attributes][title]"]',
    ).val();

    if (useOnlineVoting === 'false') {
      $('.confirm-use-online-voting').append(
        '<div class="row">' +
          '<div class="col-6 font-weight-bold">電子投票</div>' +
          '<div class="col-6">利用しない</div>' +
          '</div>',
      );
      $('.confirm-general-meeting').hide();
      $('.confirm-general-meeting-agenda').hide();
    } else {
      $('.confirm-use-online-voting').append(
        '<div class="row">' +
          '<div class="col-6 font-weight-bold">電子投票</div>' +
          '<div class="col-6">利用する</div>' +
          '</div>',
      );

      $('.confirm-general-meeting').show();
      $('.confirm-general-meeting').append('<div class="row"></div>');
      $('.confirm-general-meeting')
        .find('.row')
        .append(
          '<div class="col-6 font-weight-bold">株主総会名</div>' +
            `<div class="col-6">${generalMeetingTitle}</div>` +
            '<div class="col-6 font-weight-bold">株主総会の基準日</div>' +
            `<div class="col-6">${convineBaseAtMeeting()}</div>` +
            '<div class="col-6 font-weight-bold">電子投票の締切日時</div>' +
            `<div class="col-6">${convineFinishAtVoting()}</div>` +
            '<div class="col-6 font-weight-bold">株主総会の開催日時</div>' +
            `<div class="col-6">${convineStartAtMeeting()}</div>`,
        );
      $('.confirm-general-meeting-agenda').show();
      $('.confirm-general-meeting-agenda').append('<div class="row"></div>');
      $('.general-meeting-agenda-form').each((index, element) => {
        const $this = $(element);
        if (!$this.is(':hidden')) {
          let agendaNumber = $this
            .find('.agenda-number-input')
            .find('input')
            .val();
          let agendaTitle = $this
            .find('.agenda-title')
            .find('input')
            .val();
          let isChoice = $this.find('input[type="checkbox"]').prop('checked');

          $('.confirm-general-meeting-agenda')
            .find('.row')
            .append(
              `<div class="col-6 font-weight-bold">第${agendaNumber}号議案</div>` +
                `<div class="col-6">${agendaTitle}</div>`,
            );

          if (isChoice) {
            let choices = $this.find('.agenda-choice');
            $('.confirm-general-meeting-agenda')
              .find('.row')
              .append('<div class="col-6 font-weight-bold">除外項目</div>');
            choices.each((index, element) => {
              const name = $(element).val();
              $('.confirm-general-meeting-agenda')
                .find('.row')
                .append(
                  `<div class="col-6 ${
                    index === 0 ? '' : 'offset-6'
                  }">${name}</div>`,
                );
            });
          }
        }
      });
    }

    function convineBaseAtMeeting() {
      const atYear = $(
        'select[name="company_ir[company_general_meeting_attributes][base_at_meeting(1i)]"]',
      ).val();
      const atMonth = $(
        'select[name="company_ir[company_general_meeting_attributes][base_at_meeting(2i)]"]',
      ).val();
      const atDay = $(
        'select[name="company_ir[company_general_meeting_attributes][base_at_meeting(3i)]"]',
      ).val();
      return `${atYear}年${atMonth}月${atDay}日`;
    }

    function convineFinishAtVoting() {
      const atYear = $(
        'select[name="company_ir[company_general_meeting_attributes][finish_at_voting(1i)]"]',
      ).val();
      const atMonth = $(
        'select[name="company_ir[company_general_meeting_attributes][finish_at_voting(2i)]"]',
      ).val();
      const atDay = $(
        'select[name="company_ir[company_general_meeting_attributes][finish_at_voting(3i)]"]',
      ).val();
      const atHour = $(
        'select[name="company_ir[company_general_meeting_attributes][finish_at_voting(4i)]"]',
      ).val();
      const atMinute = $(
        'select[name="company_ir[company_general_meeting_attributes][finish_at_voting(5i)]"]',
      ).val();
      return `${atYear}年${atMonth}月${atDay}日 ${atHour}時${atMinute}分`;
    }

    function convineStartAtMeeting() {
      const atYear = $(
        'select[name="company_ir[company_general_meeting_attributes][start_at_meeting(1i)]"]',
      ).val();
      const atMonth = $(
        'select[name="company_ir[company_general_meeting_attributes][start_at_meeting(2i)]"]',
      ).val();
      const atDay = $(
        'select[name="company_ir[company_general_meeting_attributes][start_at_meeting(3i)]"]',
      ).val();
      const atHour = $(
        'select[name="company_ir[company_general_meeting_attributes][start_at_meeting(4i)]"]',
      ).val();
      const atMinute = $(
        'select[name="company_ir[company_general_meeting_attributes][start_at_meeting(5i)]"]',
      ).val();
      return `${atYear}年${atMonth}月${atDay}日 ${atHour}時${atMinute}分`;
    }
  }

  renderConfirmStartAtIr() {
    $('#confirm_start_at_ir').text(convineStartAtIr());

    function convineStartAtIr() {
      const atYear = $('select[name="company_ir[started_at(1i)]"]').val();
      const atMonth = $('select[name="company_ir[started_at(2i)]"]').val();
      const atDay = $('select[name="company_ir[started_at(3i)]"]').val();
      const atHour = $('select[name="company_ir[started_at(4i)]"]').val();
      const atMinute = $('select[name="company_ir[started_at(5i)]"]').val();

      return `${atYear}年${atMonth}月${atDay}日 ${atHour}時${atMinute}分`;
    }
  }
}
