// @flow
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
// $FlowFixMe
import Settings from '../config/settings.js.erb';

const railsEnv = Settings.app.railsEnv;
if (railsEnv !== 'development' && railsEnv !== 'test') {
  Sentry.init({
    dsn:
      'https://ff33f2e09a53454eb08f8f3d11208917@o4504049689952256.ingest.sentry.io/4504960222101504',
    environment: railsEnv,
    integrations: [new BrowserTracing()],
    debug: railsEnv === 'development',
    allowUrls:
      railsEnv === 'staging'
        ? [/^https:\/\/fundinno-stg6dw\.com.*$/]
        : [/^https:\/\/fundinno\.com.*$/],
    ignoreErrors: [
      'Failed to fetch',
      'Load failed',
      'identifier is a reserved word',
      'Cannot call method "getRegistrations" of undefined',
      // YouTube API
      'switchVideo',
      'playVideo',
      'YT is not defined',
      'YT.Player is not a constructor',
      "Can't find variable: YT",
    ],
  });
}
